import React from 'react';
import {aboutPage, aboutHeader, aboutText, aboutEmail} from '../styles/layout/about/index.module.scss';
const About = ()=>{

    return(
            <div className={aboutPage}>
            <h2 className={aboutHeader}>
                SudoSix
            </h2>
            <p className={aboutText}>
            SUDOSIX is a multidisciplanary artist navigating the world of art and music as they are applied to new and future technologies. Between neural networks, customized smart contracts and web3 design there  few areas left untouched.
            </p>
            <a className={aboutEmail}>sudosix@sudomail.com</a>
            </div>
                );
};


export default About;